import React, { useState, useEffect } from 'react'
import { useNavigate, useParams} from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import {Form, Button, Row, Col, Spinner} from 'react-bootstrap';
import RequestProductModal from './RequestProductModal';

const requiredField = yup.string().required('Este campo es requerido');

const schema = yup.object().shape({
  products: yup.array().of(
    yup.object().shape({
      product_id: yup.number().typeError('Debe ser un número').required('Este campo es requerido'),
      quantity: yup.string().required('Este campo es requerido').matches(/^\d+(\.\d+)?$/, 'Debe ser un número'),
      quantity_unit: yup.string().required('Este campo es requerido'),
    })
  ),
  client_id: yup.number().required('Este campo es requerido'),
  incoterm: yup.string().required('Este campo es requerido'),
  requested_delivery_date: yup.date().nullable().typeError('Debe ingresar una fecha').required('Este campo es requerido'),
  arrival_city: requiredField,
  payment_terms: yup.number().typeError('Debe ser un número').required('Este campo es requerido').positive().integer(),
  buy_type: requiredField,
  delivery_details: requiredField,
});

function RequestForm() {
  const axiosInstance = useAxiosPrivate();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { requestId } = useParams();
  const [clientName, setClientName] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);

  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
      resolver: yupResolver(schema),
      defaultValues: {
        products: [
          {
            quantity_unit: 'KG',
          },
        ],
        incoterm: 'Local',
        buy_type: 'Frecuente',
      }
  });

  //Selección de producto
  const handleSelectProduct = (product) => {
    const productExists = selectedProducts.some(item => item.product_id === product.product_id);
    
    if (productExists) {
      setSelectedProducts(prevSelected =>
        prevSelected.filter(item => item.product_id !== product.product_id)
      );
    } else {
      setSelectedProducts(prevSelected => [
        ...prevSelected, 
        {
          product_id: product.product_id, 
          product_name: product.product_name,
          quantity: product.quantity || '', 
          quantity_unit: product.quantity_unit || 'KG'
        }
      ]);
    }
  };
  
  /*
  const handleSelectProduct = (product) => {
    if (selectedProducts.some(item => item.product_id === product.product_id)) {
      setSelectedProducts([]);
  } else {
      setSelectedProducts([{ 
        product_id: product.product_id, 
        product_name: product.product_name,
        quantity: product.quantity || '', 
        quantity_unit: product.quantity_unit || 'KG' 
      }]);
  }};
  */

    useEffect(() => {
        if (selectedProducts.length > 0) {
        setValue('products', selectedProducts);

        } else {
        setValue('products', []); 
        }
    }, [selectedProducts, setValue]);

    useEffect(() => {
        console.log('Request ID:', requestId);
        const fetchRequestData = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get(`/api/quote/${requestId}/`);
                const data = response.data;
                console.log('Data:', data);

                const fetchedProducts = response.data.products.map(product => ({
                product_id: product.product_id || "", 
                product_name: product.product_name || "", 
                quantity: product.quantity || "", 
                quantity_unit: product.quantity_unit || "KG", 
                }));
                console.log('Fetched Products:', fetchedProducts);
    
            reset(data);
            setSelectedProducts(fetchedProducts); 
            setClientName(data.client_name);

            } catch (error) {
            console.error('Error fetching request data:', error);
            console.log('Error:', error);
            setLoading(false);
            } finally {
            setLoading(false);
            }
        };

      fetchRequestData(requestId);

  }, [requestId, reset]);

  const deleteProduct = (id) => {
    setSelectedProducts(prevProducts => prevProducts.filter(product => product.product_id !== id));
  };
  
  const formatDate = (date) => {
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, '0');
    const day = `${d.getDate()}`.padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };
  
  //enviar formulario
  const onSubmit = async (data) => {  
    setLoading(true);
    const formattedData = {
      ...data,
      requested_delivery_date: formatDate(data.requested_delivery_date),
    };
    
    try{
      setLoading(true);
        await axiosInstance.put(`/api/quote/${requestId}/`, formattedData);
        navigate(`/request/details/${requestId}`);

    } catch (error) {
      console.error('Failed to submit request:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return(
    <div className='loading-container'>
      <p style={{fontFamily: 'Roboto, sans-serif'}}>Cargando...</p>
      <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem'}} />
    </div>
    );
  }

  return (
    <>
    <div className='maestros-form'>
        <h2>Editar Solicitud</h2>
        <p style={{margin: '5px 0', fontSize: '17px'}}> Edita la solicitud correspondiente a </p>
        <p style={{fontSize: '1.2rem'}}><strong>Cliente:</strong> {clientName ? clientName : 'Cliente no seleccionado'}</p>
        <hr />
        
        <Form onSubmit={handleSubmit(onSubmit)}>
        <h4 style={{fontWeight: '700', color: '#07514F'}}>
            {selectedProducts.length > 1 ? 'Información de los Productos' : 'Información del Producto'}
        </h4>
        <Button
            type='button'
            variant="outline-primary"
            style={{
                width: '20%',
                margin: '10px 0', 
                padding: '6px 12px', 
                borderRadius: '5px', 
                cursor: 'pointer'
            }}
            onClick={() => setShowModal(true)}

        >
            <strong>Añadir Producto</strong>
        </Button>
        <RequestProductModal
            showModal={showModal}
            closeModal={() => setShowModal(false)}
            handleSelectProduct={handleSelectProduct}
            selectedProduct={selectedProducts.length > 0 ? selectedProducts[0] : null}
            selectedProducts={selectedProducts} 
        />

        {selectedProducts.map((product, index) => (
            <Row key={ product.product_id }>
                <Row>
                <Col style={{display: 'flex', alignItems: 'center'}}>
                <h5 style={{fontWeight: '500'}}> <strong>Producto seleccionado:</strong> {product.product_name}</h5>
                </Col>

                <Col style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                <button 
                    style={{
                        border: 'none',
                        color: 'red',
                        backgroundColor: 'transparent',
                        padding: '6px 12px',
                        margin: '0px 0px 20px 0px', 
                        cursor: 'pointer'
                    }}
                    type='button'
                    onClick={() => deleteProduct(product.product_id)}
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                </svg>
                </button>
                </Col>
                </Row>
                
                {/* Primera columna */}
                <Col>
                <Form.Group controlId={`products[${index}].product_id`}>
                    <Form.Label>ID del producto</Form.Label>
                    <Form.Control 
                    type="number" 
                    name={`products[${index}].product_id`} 
                    {...register(`products[${index}].product_id`)} 
                    disabled
                    />
                    <Form.Control.Feedback type="invalid">
                    {errors.products?.[index]?.product_id?.message}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId={`products[${index}].quantity`}>
                    <Form.Label>Cantidad</Form.Label>
                    <Form.Control
                    type="number"
                    name={`products[${index}].quantity`}
                    {...register(`products[${index}].quantity`)}
                    isInvalid={!!errors.products?.[index]?.quantity}
                    />
                    <Form.Control.Feedback type="invalid">
                    {errors.products?.[index]?.quantity?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                </Col>
                {/* Segunda columna */}
                <Col>
                <Form.Group controlId={`products[${index}].quantity_unit`}>
                    <Form.Label>Unidad</Form.Label>
                    <Form.Select
                    name={`products[${index}].quantity_unit`}
                    {...register(`products[${index}].quantity_unit`)}
                    isInvalid={!!errors.products?.[index]?.quantity_unit}
                    >
                    <option value="KG">Kilogramos</option>
                    <option value="G">Gramos</option>
                    <option value="TON">Tonelada</option>
                    <option value="TON_S">Tonelada corta</option>
                    <option value="TON_L">Tonelada larga</option>
                    <option value="LB">Libra</option>
                    <option value="OZ">Onzas</option>
                    <option value="L">Litro</option>
                    <option value="ML">Mililtro</option>
                    <option value="GAL_UK">Galón UK</option>
                    <option value="GAL_US">Galón US</option>
                    <option value="FCL">FCL</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                    {errors.products?.[index]?.quantity_unit?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                </Col>
            </Row>
        ))}

            <hr />
            <h4 style={{fontWeight: '700', color: '#07514F'}}>Información de la solicitud</h4>  
            <Row>
            {/* Primera columna */}
            <Col>
            <Form.Group controlId='client_id'>
                <Form.Label>ID del cliente</Form.Label>
                <Form.Control type="number" name="client_id" {...register('client_id')} disabled/>
                <Form.Control.Feedback type="invalid">
                    {errors.client_id?.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='incoterm'>
                <Form.Label>Incoterm</Form.Label>
                <Form.Select type="text" name="incoterm" {...register('incoterm')} isInvalid={!!errors.incoterm}>
                <option value="Local">Local</option>
                <option value="CIF">CIF</option>
                <option value="CIP">CIP</option>
                <option value="FOB">FOB</option>
                <option value="EXW">EXW</option>
                <option value="FCA">FCA</option>
                <option value="FAS">FAS</option>
                <option value="CFR">CFR</option>
                <option value="CPT">CPT</option>
                <option value="DPU">DPU</option>
                <option value="DAP">DAP</option>
                <option value="DDP">DDP</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                {errors.incoterm?.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='payment_terms'>
                <Form.Label>Terminos de pago (días)</Form.Label>
                <Form.Control 
                type="number" 
                name="payment_terms" 
                pattern="^[0-9]+$"  
                min='0'
                onKeyDown={(e) => e.key === 'ArrowUp' || e.key === 'ArrowDown' ? e.preventDefault() : null}
                onWheel={(e) => e.preventDefault()}
                {...register('payment_terms')}
                isInvalid={!!errors.payment_terms}
                />
                <Form.Control.Feedback type="invalid">
                {errors.payment_terms?.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='buy_type'>
                <Form.Label>Tipo de compra</Form.Label>
                <Form.Select type="text" name="buy_type" {...register('buy_type')} isInvalid={!!errors.buy_type}>
                <option value="Frecuente">Frecuente</option>
                <option value="Desarrollo">Desarrollo</option>
                <option value="Homologación">Homologación</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                {errors.incoterm?.message}
                </Form.Control.Feedback>
            </Form.Group>
            </Col>

            {/* Segunda columna */}
            <Col>
            <Form.Group controlId='requested_delivery_date'>
                <Form.Label>Fecha de llegada solicitada</Form.Label>
                <Form.Control 
                type="date" 
                name="requested_delivery_date" 
                {...register('requested_delivery_date')} 
                isInvalid={!!errors.requested_delivery_date}
                />
                <Form.Control.Feedback type="invalid">
                {errors.requested_delivery_date?.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='arrival_city'>
                <Form.Label>Ciudad de llegada</Form.Label>
                <Form.Control type="text" name="arrival_city" {...register('arrival_city')} isInvalid={!!errors.arrival_city} />
                <Form.Control.Feedback type="invalid">
                {errors.arrival_city?.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='delivery_details'>
              <Form.Label>Detalles de entrega</Form.Label>
              <Form.Control type="text" name="delivery_details" {...register('delivery_details')} isInvalid={!!errors.delivery_details} />
              <Form.Control.Feedback type="invalid">
                {errors.delivery_details?.message}
              </Form.Control.Feedback>
            </Form.Group>
            </Col>
            </Row>

            <Button type='submit'>Editar Solicitud</Button>
        </Form>
    </div>

    </>
  )
}

export default RequestForm