import './App.css';
import {BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom"
import { useAuth, AuthProvider } from './context/AuthContext';

//COMPONENTS
import Header from './components/Header';
import AppHeader from './appcomponents/AppHeader';
import Footer from './components/Footer';
import AppFooter from './appcomponents/AppFooter';
import PrivateRoute from './appcomponents/PrivateRoute';
import Navbar from './appcomponents/Sidebar';
import PasswordReset from './components/PasswordReset';
import PasswordForget from './components/PasswordForget';

//LAYOUTS
import About from './pages/About';
import Products from './pages/Products';
import Sesion from './pages/Sesion';
import Services from './pages/Services';
import ContactUs from './pages/ContactUs';

//APPLAYOUTS
import AuthRedirect from './context/AuthRedirect';
import Home from './applayouts/Home';

/*CLIENTES */
import ClientsForm from './applayouts/Clients/ClientsForm';
import ClientesList from './applayouts/Clients/ClientsList';
import ClientsDetails from './applayouts/Clients/ClientsDetails';

/*PROVEEDORES */
import SuppliersForm from './applayouts/Suppliers/SuppliersForm';
import SuppliersList from './applayouts/Suppliers/SuppliersList';

/*PRODUCTOS */
import ProductsForm from './applayouts/Products/ProductsForm';
import ProductsList from './applayouts/Products/ProductsList';
import ProductsDetails from './applayouts/Products/ProductsDetails';
import SuppliersDetails from './applayouts/Suppliers/SuppliersDetails';
import Unauthorized from './appcomponents/Unauthorized';
import PersistentLogin from './context/PersistentLogin';

/*REQUEST */
import RequestForm from './applayouts/QuotesRequest/RequestForm';
import RequestFormUpdate from './applayouts/QuotesRequest/RequestFormUpdate';
import RequestList from './applayouts/QuotesRequest/RequestList';
import RequestDetails from './applayouts/QuotesRequest/RequestDetails';
import ProductsCost from './applayouts/QuotesRequest/ProductsCost';

/*COSTOS */
import CostForm from './applayouts/QuotesCost/CostForm';
import CostFormUpdate from './applayouts/QuotesCost/CostFormUpdate';
import CostList from './applayouts/QuotesCost/CostList';
import CostDetails from './applayouts/QuotesCost/CostDetails';

/*ORDENES */
import OrderForm from './applayouts/QuotesOrder/OrderForm';
import OrderList from './applayouts/QuotesOrder/OrderList';
import OrderDetails from './applayouts/QuotesOrder/OrderDetails';

/*CONFIGURACIÓN */
import Profile from './applayouts/Settings/Profile';
import UserForm from './applayouts/Settings/UserForm';
import UserClientForm from './applayouts/Settings/UserClientForm';
import UserDetails from './applayouts/Settings/UserDetails';


function App() { 

  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

function AppContent() {
  const location = useLocation();
  const isSesion = location.pathname.includes('/signin');
  const { isAuthenticated } = useAuth();


  return (
    <div className="App"> 
      {(isAuthenticated || isSesion)  ? <AppHeader /> : <Header />}
      <div className="container-fluid" style={{padding: '0px'}}>
        <div className="row" style={{margin: '0px'}}>
          {(isAuthenticated) && (
            <div className="col-md-3">
              <Navbar />
            </div>
          )}
          <div className={(isAuthenticated) ? "col-md-9" : "col-12"}>
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<AuthRedirect/>}/>
              <Route path="/nosotros" element={<About/>}/>
              <Route path="/productos" element={<Products/>}/>
              <Route path="/servicios" element={<Services/>}/>
              <Route path="/contacto" element={<ContactUs/>}/>
              <Route path="/signin" element={<Sesion/>}/>
              <Route path="/unauthorized" element={<Unauthorized/>}/>
              <Route path="/signin/forgetpassword" element={<PasswordForget/>}/>
              <Route path="/reset/password" element={<PasswordReset/>}/>
              

              {/* App Layouts */}
              <Route element={<PersistentLogin />}>
                <Route element={<PrivateRoute />}>
                  <Route path="/home" element={<Home />} />
                </Route>

                {/* Clients */}
                <Route element={<PrivateRoute feature="cliente" action="create"/>}>
                  <Route path="/clientsform" element={<ClientsForm />} />
                </Route>
                <Route element={<PrivateRoute feature="cliente" action="update"/>}>
                  <Route path="/clientsform/:clientId" element={<ClientsForm />} />
                </Route>
                <Route element={<PrivateRoute feature="cliente" action="read"/>}>
                  <Route path="/clientslist" element={<ClientesList />} />
                  <Route path="/client/details/:clientId" element={<ClientsDetails />} />
                </Route>

                {/* Suppliers */}
                <Route element={<PrivateRoute feature="proveedor" action="create"/>}>
                  <Route path="/suppliersform" element={<SuppliersForm />} />
                </Route>
                <Route element={<PrivateRoute feature="proveedor" action="update"/>}>
                  <Route path="/suppliersform/:supplierId" element={<SuppliersForm />} />
                </Route> 
                <Route element={<PrivateRoute feature="proveedor" action="read"/>}>
                  <Route path="/supplierslist" element={<SuppliersList />} />
                  <Route path="/supplier/details/:supplierId" element={<SuppliersDetails />} />
                </Route>

                {/* Products */} 
                <Route element={<PrivateRoute feature="producto" action="create"/>}>
                  <Route path="/productsform" element={<ProductsForm />} />
                </Route>
                <Route element={<PrivateRoute feature="producto" action="update"/>}>
                  <Route path="/productsform/:productId" element={<ProductsForm />} />
                </Route> 
                <Route element={<PrivateRoute feature="producto" action="read"/>}>
                  <Route path="/productslist" element={<ProductsList />} />
                  <Route path="/products/details/:productId" element={<ProductsDetails />} />
                </Route>

                {/* Request */}
                <Route element={<PrivateRoute feature="solicitud" action="create" />}>
                  <Route path="/requestform" element={<RequestForm />} />
                </Route>

                <Route element={<PrivateRoute feature="solicitud" action="update" />}>
                  <Route path="/requestform/:requestId" element={<RequestFormUpdate />} />
                </Route>

                <Route element={<PrivateRoute feature="solicitud" action="read" />}>
                  <Route path="/requestlist" element={<RequestList />} />
                  <Route path="/request/details/:requestId" element={<RequestDetails />} />
                </Route>
 
                <Route element={<PrivateRoute feature="solicitud" action="read" />}>
                  <Route path="/requestcost/:requestId" element={<ProductsCost />} />
                </Route>

                {/* Cost */}
                <Route element={<PrivateRoute feature="costos" action="read" />}>
                  <Route path="/costlist" element={<CostList />} />
                  <Route path="/cost/details/:costId" element={<CostDetails />} />
                </Route>

                <Route element={<PrivateRoute feature="costos" action="create" />}>
                  <Route path="/costform" element={<CostForm />} />
                </Route>

                <Route element={<PrivateRoute feature="costos" action="update" />}>
                  <Route path="/costform/:costId" element={<CostFormUpdate />} />
                  <Route path="/costform-from-request" element={<CostFormUpdate />} />
                </Route>

                {/* Orders */}
                <Route element={<PrivateRoute feature="cotizaciones" action="read" />}>
                  <Route path="/quoteslist" element={<OrderList />} />
                  <Route path="/quote/details/:quoteId" element={<OrderDetails />} />
                </Route>

                <Route element={<PrivateRoute feature="cotizaciones" action="update" />}>
                  <Route path="/consignee/:quoteId" element={<OrderForm />} />
                </Route>

                {/* Settings */}
                <Route element={<PrivateRoute />}>
                  <Route path="/settings" element={<Profile />} />

                  <Route path="/settings/userform" element={<UserForm />} />
                  <Route path="/settings/userform/:userId" element={<UserForm />} />

                  <Route path="/settings/userclientform" element={<UserClientForm />} />

                  <Route path="/settings/userdetails/:userId" element={<UserDetails />} />
                </Route>

              </Route>        
            </Routes>
          </div>
        </div>
      </div>
      {(isAuthenticated || isSesion)  ? <AppFooter /> : <Footer />}
    </div>
  );
}

export default App;
