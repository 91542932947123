import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import {Form, Button, Row, Col, Spinner, Toast} from 'react-bootstrap';
import CostClient from './CostClient';
import CostProduct from './CostProduct';
import CostSupplier from './CostSupplier';

const requiredField = yup.string().required('Este campo es requerido');

const schema = yup.object().shape({
  client_id: yup.number().required('Este campo es requerido'),
  product_id: yup.number().required('Este campo es requerido'),
  supplier_id: yup.number().required('Este campo es requerido'),
  incoterm: yup.string().required('Este campo es requerido'),
  product_detail_id: yup
    .number()
    .typeError('Debe seleccionar un empaque')
    .required('Debe seleccionar un empaque')
    .transform((value, originalValue) => (originalValue === '' ? undefined : value)),
  min_order_quantity: yup
    .number()
    .typeError('Debe ser un número')
    .required('Este campo es requerido')
    .positive('debe ser un número positivo')
    .integer('debe ser un número entero'),
  min_order_quantity_unit: requiredField,
  validity: yup
  .number()
  .typeError('Debe ser un número')
  .required('Este campo es requerido')
  .positive('debe ser un número positivo')
  .integer('debe ser un número entero'),
  unit_price: yup.number().typeError('Debe ser un número').required('Este campo es requerido').positive('debe ser un número positivo'),
  currency: requiredField,
  estimated_delivery_weeks: yup
    .number()
    .typeError('Debe ser un número')
    .required('Este campo es requerido')
    .positive('debe ser un número positivo')
    .integer('debe ser un número entero'),
  observation: yup.string().nullable(),
});

function CostForm() {
  const axiosInstance = useAxiosPrivate();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  //seleccionables
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientName, setClientName] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productName, setProductName] = useState('');
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [supplierName, setSupplierName] = useState('');
  //Detalles del producto
  const [productDetails, setProductDetails] = useState([]);


  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
      resolver: yupResolver(schema),
      defaultValues: {
        incoterm: 'Local',
        currency: 'USD',
      }
  });

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  //Selección de cliente
  const handleSelectClient = (client) => {
    if (typeof setSelectedClient === 'function') {
      if (selectedClient === null) {
        setSelectedClient(client.client_id);
        setClientName(client.client_name);

      } else if (selectedClient === client.client_id) {
        setSelectedClient(null);
        setClientName('');

      } else {
        console.log('Ya hay un cliente seleccionado:', selectedClient);
      }
    } else {
      console.error('setSelectedClient is not a function');
    }
  };

  //Selección de producto
  const handleSelectProduct = (product) => {
    if (typeof setSelectedProduct === 'function') {
      if (selectedProduct === null) {
        setSelectedProduct(product.product_id);
        setProductName(product.product_name);

      } else if (selectedProduct === product.product_id) {
        setSelectedProduct(null);
        setProductName('');

      } else {
        console.log('Ya hay un producto seleccionado:', selectedProduct);
      }
    } else {
      console.error('setSelectedProduct is not a function');
    }
  };

  //selección de proveedor
  const handleSelectSupplier = (supplier) => {
    if (typeof setSelectedSupplier === 'function') {
      if (selectedSupplier === null) {
        setSelectedSupplier(supplier.supplier_id);
        setSupplierName(supplier.supplier_name);

      } else if (selectedSupplier === supplier.supplier_id) {
        setSelectedSupplier(null);
        setSupplierName('');

      } else {
        console.log('Ya hay un proveedor seleccionado:', selectedSupplier);
      }
    } else {
      console.error('setSelectedSupplier is not a function');
    }
  };

  useEffect(() => {
    if (selectedClient) {
      setValue('client_id', selectedClient);
    } else {  
      setValue('client_id', '');  
    }
    if(clientName){
      setValue('client_name', clientName);
    } else {
      setValue('client_name', '');
    }
    if (selectedProduct) {
      axiosInstance.get(`api/product-detail/?product_id=${selectedProduct}`)
        .then((response) => {
          setProductDetails(response.data);
        })
        .catch((error) => {
          console.error('Error fetching product details:', error);
        });
      setValue('product_id', selectedProduct);
    } else {
      setValue('product_id', ''); 
    }
    if (selectedSupplier) {
      setValue('supplier_id', selectedSupplier);
    } else {
        setValue('supplier_id', '');
    }
    if (supplierName){
      setValue('supplier_name', supplierName);
    } else {
      setValue('supplier_name', '');
    }
  }, [selectedClient, clientName, selectedProduct, selectedSupplier, supplierName, axiosInstance, setValue]);
   
  //enviar formulario
  const onSubmit = async (data) => {  
    setLoading(true);
    try{
        await axiosInstance.post('api/quote-cost/', data);

        setTimeout(() => {
          navigate(`/costlist`);
        }, 1000);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error === "A QuoteCost with the same supplier_id, client_id, product_id and incoterm already exists.") {
        setShowToast(true);
      } else {
        console.error('Failed to submit request:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return(
    <div className='loading-container'>
      <p style={{fontFamily: 'Roboto, sans-serif'}}>Cargando...</p>
      <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem'}} />
    </div>
    );
  }

  const renderStep = () => {
    switch (currentStep) {
        case 1:
            return (
            <>
              <h2>Selecciona un Cliente</h2>
              <p style={{margin: '5px 0', fontSize: '17px'}}>
              Selecciona un cliente para continuar con el costeo.
              </p>
              <hr />
              <CostClient 
              selectedClient={selectedClient} 
              handleSelectClient={handleSelectClient}
              /> 
              {selectedClient && (
              <Button onClick={handleNextStep}>Siguiente: Producto</Button>
              )}
            </>
            );
        case 2:
            return (
            <>
              <h2>Selecciona un Producto</h2>
              <p style={{margin: '5px 0', fontSize: '17px'}}>
              Selecciona un producto para continuar con el costeo.
              </p>
              <hr />
              <CostProduct 
              selectedProduct={selectedProduct} 
              handleSelectProduct={handleSelectProduct}
              /> 
              {selectedProduct && (
              <Button onClick={handleNextStep}>Siguiente: Proveedor</Button>
              )}
              <Button onClick={handlePrevStep}>Volver a Selección de Cliente</Button>
            </>
            );
        case 3:
            return (
              <>
                <h2>Selecciona un Proveedor</h2>
                <p style={{margin: '5px 0', fontSize: '17px'}}>
                Selecciona un proveedor para continuar con el costeo.
                </p>
                <hr />
                <CostSupplier
                selectedSupplier={selectedSupplier}
                handleSelectSupplier={handleSelectSupplier}
                /> 
                {selectedProduct && (
                <Button onClick={handleNextStep}>Siguiente: Formulario</Button>
                )}
                <Button onClick={handlePrevStep}>Volver a Selección de Producto</Button>
              </>
            );
        case 4:
            return (
            <>
              <h2>Completa el Formulario</h2>
              <p style={{margin: '5px 0', fontSize: '17px'}}>
                  <p>Diligencia los campos para enviar el costeo correspondiente a:</p>
                  <p style={{fontSize: '1.2rem'}}><strong>Cliente:</strong> {clientName ? clientName : 'Cliente no seleccionado'}</p>
                  <p style={{fontSize: '1.2rem'}}><strong>Proveedor:</strong> {supplierName ? supplierName : 'Proveedor no seleccionado'}</p>
              </p>
              <hr />
              <Form onSubmit={handleSubmit(onSubmit)}>
              <h4 style={{fontWeight: '700', color: '#07514F'}}>Información del Producto</h4>
              <h5 style={{fontWeight: '500'}}> <strong>Producto seleccionado:</strong> {productName}</h5>
              <Row>
                  {/* Primera columna */}
                  <Col>
                  <Form.Group controlId='product_id'>
                      <Form.Label>ID del producto</Form.Label>
                      <Form.Control type="number" name="client_id" {...register('product_id')} disabled/>
                      <Form.Control.Feedback type="invalid">
                      {errors.product_id?.message}
                      </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId='min_order_quantity'>
                  <Form.Label>Cantidad mínima de orden</Form.Label>
                  <Form.Control 
                      type="number" 
                      name="min_order_quantity" 
                      pattern="^[0-9]+$"  
                      min='0'
                      onKeyDown={(e) => e.key === 'ArrowUp' || e.key === 'ArrowDown' ? e.preventDefault() : null}
                      onWheel={(e) => e.preventDefault()}
                      {...register('min_order_quantity')}
                      isInvalid={!!errors.min_order_quantity}
                  />
                  <Form.Control.Feedback type="invalid">
                      {errors.min_order_quantity?.message}
                  </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId={`min_order_quantity_unit`}>
                  <Form.Label>Unidad</Form.Label>
                  <Form.Select
                      name={`min_order_quantity_unit`}
                      {...register(`min_order_quantity_unit`)}
                      isInvalid={!!errors.min_order_quantity_unit}
                  >
                      <option value="KG">Kilogramos</option>
                      <option value="G">Gramos</option>
                      <option value="TON">Tonelada</option>
                      <option value="TON_S">Tonelada corta</option>
                      <option value="TON_L">Tonelada larga</option>
                      <option value="LB">Libra</option>
                      <option value="OZ">Onzas</option>
                      <option value="L">Litro</option>
                      <option value="ML">Mililtro</option>
                      <option value="GAL_UK">Galón UK</option>
                      <option value="GAL_US">Galón US</option>
                      <option value="FCL">FCL</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                      {errors.min_order_quantity_unit?.message}
                  </Form.Control.Feedback>
                  </Form.Group>

                  </Col>

                  {/* Segunda columna */}
                  <Col>
                  <Form.Group controlId='currency'>
                      <Form.Label>Moneda</Form.Label>
                      <Form.Select 
                        type="text" 
                        name="currency" 
                        {...register('currency')}
                        isInvalid={!!errors.currency}
                      >
                      <option value="USD">USD</option>
                      <option value="COP">COP</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                      {errors.currency?.message}
                      </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId='unit_price'>
                      <Form.Label>Precio Unitario</Form.Label>
                      <Form.Control type="number" name="unit_price" {...register('unit_price')}/>
                      <Form.Control.Feedback type="invalid">
                      {errors.unit_price?.message}
                      </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId={`product_detail_id`}>
                  <Form.Label>Empaque</Form.Label>
                  <Form.Select
                      name={`product_detail_id`}
                      {...register(`product_detail_id`)}
                      isInvalid={!!errors.product_detail_id}
                  >
                      <option value="">Selecciona un empaque</option>
                      {productDetails.map(detail => (
                      <option key={detail.id} value={detail.id}>
                          {`${detail.packaging_size} ${detail.unity} en ${detail.packaging_type}`}
                      </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                      {errors.product_detail_id?.message}
                  </Form.Control.Feedback>
                  </Form.Group>

                  </Col>
              </Row>

              <hr />
              <h4 style={{fontWeight: '700', color: '#07514F'}}>Información del Costeo</h4> 
              
              <Row>
                  {/* Primera columna */} 
                  <Col>
                  <Form.Group controlId='client_id'>
                      <Form.Label>ID del cliente</Form.Label>
                      <Form.Control type="number" name="client_id" {...register('client_id')} disabled/>
                      <Form.Control.Feedback type="invalid">
                      {errors.client_id?.message}
                      </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId='supplier_id'>
                      <Form.Label>ID del proveedor</Form.Label>
                      <Form.Control type="number" name="supplier_id" {...register('supplier_id')} disabled/>
                      <Form.Control.Feedback type="invalid">
                      {errors.supplier_id?.message}
                      </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId='incoterm'>
                  <Form.Label>Incoterm</Form.Label>
                  <Form.Select type="text" name="incoterm" {...register('incoterm')} isInvalid={!!errors.incoterm}>
                      <option value="Local">Local</option>
                      <option value="CIF">CIF</option>
                      <option value="CIP">CIP</option>
                      <option value="FOB">FOB</option>
                      <option value="EXW">EXW</option>
                      <option value="FCA">FCA</option>
                      <option value="FAS">FAS</option>
                      <option value="CFR">CFR</option>
                      <option value="CPT">CPT</option>
                      <option value="DPU">DPU</option>
                      <option value="DAP">DAP</option>
                      <option value="DDP">DDP</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                      {errors.incoterm?.message}
                  </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId='validity'>
                  <Form.Label>Días de validez</Form.Label>
                  <Form.Control 
                      type="number" 
                      name="validity" 
                      pattern="^[0-9]+$"  
                      min='0'
                      onKeyDown={(e) => e.key === 'ArrowUp' || e.key === 'ArrowDown' ? e.preventDefault() : null}
                      onWheel={(e) => e.preventDefault()}
                      {...register('validity')}
                      isInvalid={!!errors.validity}
                  />
                  <Form.Control.Feedback type="invalid">
                      {errors.validity?.message}
                  </Form.Control.Feedback>
                  </Form.Group>
                  </Col>

                  {/* Segunda columna */}
                  <Col>
                  <Form.Group controlId='estimated_delivery_weeks'>
                  <Form.Label>Semanas de entrega estimadas</Form.Label>
                  <Form.Control 
                      type="number" 
                      name="estimated_delivery_week" 
                      {...register('estimated_delivery_weeks')} 
                      isInvalid={!!errors.estimated_delivery_weeks}
                  />
                  <Form.Control.Feedback type="invalid">
                      {errors.estimated_delivery_weeks?.message}
                  </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId='observation'>
                  <Form.Label>Observaciones</Form.Label>
                  <Form.Control 
                      as="textarea" 
                      rows={7} 
                      placeholder='Mensaje...' 
                      {...register('observation')}
                  />
                  </Form.Group>
                  </Col>
              </Row>

              <Button type='submit'>Crear Costeo</Button> 
              <Button onClick={handlePrevStep}>Volver a Selección de Proveedor</Button>
              </Form>
            </>
            );
        default:
            return <div>Ocurrió un error, por favor refresca la página.</div>;
        }
  };

  return (
    <div className='maestros-form'>
      {renderStep()}
      <Toast
        onClose={() => setShowToast(false)} 
        show={showToast}
        delay={8000}
        autohide 
        bg='warning'
        style={{ position: 'fixed', top: 100, right: 20, zIndex: 1 }}  // Estilo para fijarlo en la esquina superior derecha
      >
      <Toast.Header>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-lg" viewBox="0 0 16 16">
          <path d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0zM7 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0"/>
        </svg>
        <strong className="me-auto">Error</strong> 
        <small>Ahora</small>
      </Toast.Header>
      <Toast.Body>
        Ya existe un costeo con estas características.
      </Toast.Body>
      </Toast>

    </div>
  )
}

export default CostForm