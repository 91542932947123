import React, {useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Spinner, Modal, Button, ProgressBar, Form, Toast } from 'react-bootstrap';
import '../../appstyles/ClientsCSS/ClientsDetails.css'
//import PermissionWrapper from '../../context/PermissionWrapper';

const schema = yup.object().shape({
  validity: yup
  .number()
  .typeError('Debe ser un número')
  .required('Este campo es requerido')
  .positive('debe ser un número positivo')
  .integer('debe ser un número entero'),
  unit_price: yup.string().typeError('Debe ser un número').required('Este campo es requerido'),
});

function ProductsCost() {
  const axiosInstance = useAxiosPrivate();
  const { requestId } = useParams();
  const navigate = useNavigate();
  const [request, setRequest] = useState(null);
  const [costId, setCostId] = useState(null)
  const [selectedProductId, setSelectedProductId] = useState(null);
  //cantidad mínima de orden proveniente de costos
  const [minOrderQuantity, setMinOrderQuantity] = useState('');
  const [minOrderQuantityUnit, setMinOrderQuantityUnit] = useState('');
  //moneda
  const [currency, setCurrency] = useState('');
  //Modales
  const [showCostModal, setShowCostModal] = useState(false);
  const [validityModal, setValidityModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showToast, setShowToast] = useState(false); 
  //Cargando
  const [loadingProductId, setLoadingProductId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [sending, setSending] = useState(false);

  const {register, handleSubmit, formState: { errors }} = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    axiosInstance.get(`api/quote/${requestId}/`)
      .then(response => {
        setRequest(response.data);
      })
      .catch(error => {
        console.error('Failed to fetch request details', error);
      });
  }, [requestId, axiosInstance]);

  if (!request) {
    return(
    <div className='loading-container'>
      <p style={{fontFamily: 'Roboto, sans-serif'}}>Cargando...</p>
      <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem'}} />
    </div>
    );
  }

  const handleCloseModal = () => setShowCostModal(false);
  const handleGoToCostForm = () => {
    localStorage.setItem('costData', JSON.stringify({
      product_id: selectedProductId,
      product_name: request.products.find(product => product.product_id === selectedProductId).product_name,
      client_id: request.client_id,
      client_name: request.client_name, 
      supplier_id: request.supplier_id,
      supplier_name: request.supplier_name,
      incoterm: request.incoterm,
      requestId: request.quote_id 
    }));

    setShowCostModal(false);
    navigate(`/costform-from-request/?fromRequest=true`);
  };

  const handleCost = (productId) => {
    const costData = {
      product_id: productId,
      client_id: request.client_id,
      supplier_id: request.supplier_id, 
      incoterm: request.incoterm,
    };
    setLoadingProductId(productId);
    setProgress(30); 

    const params = new URLSearchParams({
      ...costData
    }).toString();

    const url = `api/quote-cost/?${params}`;

    axiosInstance.get(url)
      .then(response => {
        if (response.data.length === 0) {
            // Si la respuesta es una lista vacía, mostramos el modal
            setSelectedProductId(productId);
            setShowCostModal(true);
          } else {
            // Si la respuesta tiene un costeo válido, lo guardamos en la solicitud
            const costId = response.data[0].cost_id; 
            const isValid = response.data[0].valid;
            setCurrency(response.data[0].currency);
            setCostId(costId);

            //verificar si el costeo es valido
            if (isValid) {
              axiosInstance.post(`api/quote/set-cost/`, { 
                quote_id: +requestId, 
                cost_id: costId 
              })
              .then(() => {
                window.location.reload();
              })
              .catch(error => {
                console.error('mostrar alerta');
                if (error.response && error.response.data.message === "Min order quantity is greater than requested quantity.") {
                  axiosInstance.get(`api/quote-cost/${costId}/`)
                  .then(response => {
                    setMinOrderQuantity(response.data.min_order_quantity);
                    setMinOrderQuantityUnit(response.data.min_order_quantity_unit);
                  });
                  setShowAlert(true);
              } else {
                  console.error('Failed to set cost', error);
              }
              });
            } else {
              // Si el costeo no es válido, mostrar el modal
              axiosInstance.get(`api/quote-cost/${costId}/`)
              .then(response => {
                setMinOrderQuantityUnit(response.data.min_order_quantity_unit);
              });  
              setSelectedProductId(productId);
              setValidityModal(true);
            }
      }
      })
      .catch(error => {
        console.error('Failed to fetch request details', error);
      })
      .finally(() => {
        setProgress(100);
        setTimeout(() => {
          setLoadingProductId(null);
        }, 1000);
      });
  }

  const handleValidity = (data) => {
    if (costId){
      axiosInstance.patch(`api/quote-cost/${costId}/`, data)
      .then(() => {
        setValidityModal(false);
        axiosInstance.post(`api/quote/set-cost/`, { 
          quote_id: requestId, 
          cost_id: costId 
        })
        window.location.reload();
      })
      .catch(error => {
        console.error('Failed to update payment terms', error);
      });
  } else {
      console.error('costId is not set');
  }};

  const handleCreateOrder = async() => {
    setSending(true);
    try {
      await axiosInstance.post(`api/quote/create-order/`, {
        quote_id: +requestId,
      })
        
      navigate('/quoteslist');

    } catch (error) {
      if (error.response && error.response.status === 400) {
        setShowToast(true); 
      } else {
        console.error('Failed to create order', error);
      }
    } finally {
      setSending(false);
  }}

  return (
    <>
    <div className='maestros-details'>
        <h3><strong>Solicitud No. {request.quote_id}:</strong> {request.products.length > 1 ? `Costeo de Productos` : `Costeo de Producto` }</h3>
        <p><strong>Cliente asociado:</strong> {request.client_name} </p>
        <p><strong>Prodveedor asociado:</strong> {request.supplier_name} </p>
        <hr />

        {request.products && request.products.length > 0 ? (
          request.products.map((product) => (
          <div >
          <div key={product.product_id} className='maestros-details-actions' style={{display: 'flex', flexDirection: 'row'}}>
          <h4 style={{color: '#602548'}}>Producto: {product.product_name}</h4>

          <button 
            className='btn btn-primary' 
            style={{backgroundColor: '#025C5A', borderColor: '#025C5A '}}
            onClick={() => handleCost(product.product_id)}
            > 
            Buscar Costeo
          </button>
          </div>

          {loadingProductId === product.product_id ? (
            <div>
              <h5>Buscando costeo...</h5>
              <ProgressBar animated now={progress} />
            </div>
          ) : (
          <div className='product-details' style={{display: 'flex', flexDirection: 'row'}}>
            <div className='first-col'>
              <p><strong>ID del producto:</strong> {product.product_id} </p>
              <p><strong>Cantidad:</strong> {product.quantity} </p>
            </div>

            <div className='second-col'>   
              <p><strong>Unidad:</strong> {product.quantity_unit} </p>
              <p><strong>Precio por unidad (USD):</strong> {product.unit_price} </p>
            </div>
          </div>
          )}
          <hr />
          </div>
          ))
        ) : (
          <p>No hay productos asociados a esta solicitud</p>
        )}

        {/*Botones*/}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '5px',
          }}
        >
            <Button 
            variant="secondary" 
            onClick={() => navigate(`/request/details/${requestId}`)}
            >
              Volver
            </Button>

            <Button
              variant="primary"
              style={{backgroundColor: '#025C5A', borderColor: '#025C5A '}}
              onClick={handleCreateOrder}
            >
              {sending ? 'Enviando...' : 'Cargar Costeo'}
            </Button>
        </div>

    </div>

    <Toast
      onClose={() => setShowToast(false)} 
      show={showToast}
      delay={8000}
      autohide 
      bg='warning'
      style={{ position: 'fixed', top: 100, right: 20, zIndex: 1 }}  // Estilo para fijarlo en la esquina superior derecha
    >
      <Toast.Header>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-lg" viewBox="0 0 16 16">
          <path d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0zM7 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0"/>
        </svg>
        <strong className="me-auto">Error</strong>
        <small>Ahora</small>
      </Toast.Header>
      <Toast.Body>
        Todos los productos deben tener un precio asociado para poder cargar su costeo.
      </Toast.Body>
    </Toast>

    {showCostModal && (
      <Modal show={showCostModal}>
        <Modal.Header closeButton onHide={handleCloseModal}>
          <Modal.Title>Costeo no encontrado</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>No existe un costeo que cumpla las características asociadas al producto. ¿Deseas crear un costeo para este producto?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" style={{border: 'none'}} onClick={handleCloseModal}>Cerrar</Button>
          <Button variant="primary" style={{backgroundColor: '#025C5A', border: 'none'}} onClick={handleGoToCostForm}>Crear Costeo</Button>
        </Modal.Footer>
      </Modal>
    )}

    <Modal show={validityModal} onHide={() => setValidityModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
          </svg>
          <strong> Actualice los días de validez</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Existe un costeo asociado a este producto, pero no es valido. Por favor actualice los días de validez</p>

        <Form onSubmit={handleSubmit(handleValidity)}>
          <Form.Group controlId="valid">
            <Form.Label>Días de validez</Form.Label>
            <Form.Control 
              type="number" 
              name="payment_terms" 
              pattern="^[0-9]+$"  
              min='0'
              {...register('validity')}
              isInvalid={!!errors.validity}
            />
            <Form.Control.Feedback type="invalid">
              {errors.validity?.message}
            </Form.Control.Feedback>  
          </Form.Group>

          <Form.Group controlId="unit_price">
            <Form.Label>Precio unitario ({minOrderQuantityUnit}) {currency}</Form.Label>
            <Form.Control 
              type="text" 
              name='unit_price'
              {...register('unit_price')} 
            /> 
            <Form.Control.Feedback type="invalid">
              {errors.unit_price?.message}
            </Form.Control.Feedback>  
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" style={{border: 'none'}} onClick={() => setValidityModal(false)}>Cancelar</Button>
        <Button 
          variant="primary" 
          style={{
            backgroundColor: '#025C5A', 
            border: 'none'
          }}
          type='submit'
          onClick={handleSubmit(handleValidity)} 
        >
          Actualizar
        </Button>
      </Modal.Footer>
    </Modal>

    <Modal show={showAlert} onHide={() => setShowAlert(false)}>
    <Modal.Header closeButton>
        <Modal.Title>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
          </svg>
          <strong> Actualice la cantidad solicitada</strong>
        </Modal.Title>
    </Modal.Header>
    <Modal.Body>
          <p>
            La cantidad solicitada es menor que la cantidad mínima de compra. 
          </p>
          <p><strong>Cantidad mínima de compra:</strong> {minOrderQuantity} {minOrderQuantityUnit}</p> 
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowAlert(false)}>
            Cerrar
        </Button>
    </Modal.Footer>
    </Modal>
    
    </>
  )
}

export default ProductsCost