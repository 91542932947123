import React, {useContext, useEffect, useState, useMemo} from 'react'
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import AuthContext from '../../context/AuthContext';
import { Table, Pagination, Dropdown, DropdownButton, Row, Col, Form } from 'react-bootstrap';
import '../../appstyles/SettingsCSS/Profile.css'
import PermissionWrapper from '../../context/PermissionWrapper';

const role = {
    ADMIN : 'Administrador',
    DEV : 'Desarrollador',
    CEO: 'Director General',
    DTCA: 'Director de Calidad',
    DTLO: 'Director Logístico',
    ANAD: 'Analista Administrativo',
    DTAD: 'Director Administrativo',
    DTFI: 'Director Financiero',
    ANCO: 'Analista de Compras',
    DTCO: 'Director Comercial',
    ASCO: 'Asesor Comercial',
    AUBO: 'Auxiliar de Bodega',
    CLI: 'Cliente'
  }

function Profile() {
    const axiosInstance = useAxiosPrivate();
    const navigate = useNavigate();
    const { auth } = useContext(AuthContext);
    const [profile, setProfile] = useState('');
    const [users, setUsers] = useState([]);

    //Query params
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const rowsPerPage = 10;
    const [search, setSearch] = useState('');

    const displayRole = role[auth?.role] || 'Sin rol';

    // Fetch profile data
    useEffect(() => {
        const fetchProfile = (username) => {
            axiosInstance.get(`api/user/?username=${username}`)
            .then(response => {
                if (response.data.length > 0) {
                    setProfile(response.data[0]);
                }
            })
            .catch(error => {
                console.log('Failed to fetch profile', error);
            });
        };

        if (auth?.username) {
            fetchProfile(auth.username);
        }

    } , [auth.username, axiosInstance]); 

    //Fetch users
    useEffect(() => {
        fetchUsers(currentPage, search);
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [currentPage, search, axiosInstance]);

    const fetchUsers = (page, searchQuery) => {
        const url = searchQuery
          ? `api/user/?page=${page}&size=${rowsPerPage}&username=${searchQuery}`
          : `api/user/?page=${page}&size=${rowsPerPage}`;
    
        axiosInstance.get(url)
          .then(response => {
            console.log('Fetched data:', response.data);
            setUsers(response.data);
            setTotalPages(response.data.totalPages); // Uncomment when totalPages is available
          })
          .catch(error => {
            console.log('Failed to fetch products', error);
          });
      };

      const filteredUsers = useMemo(() => {
        if (!users || !Array.isArray(users.data)) {
          console.error('users.data is not an array:', users);
          return [];
        }
    
        return users.data.filter(user => {
          return user.auth_user.username.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      });
      }, [users, search]);

      const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
      };

    // Redirect to user details
    const handleRowClick = (userId) => {
        navigate(`/settings/userdetails/${userId}`);
      };

    // Create pagination items
    const paginationItems = [];
    for (let number = 1; number <= totalPages; number++) {
      // Only show a few items before and after the current page
      if (number === 1 || number === totalPages || (number >= currentPage - 2 && number <= currentPage + 2)) {
        paginationItems.push(
          <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
            {number}
          </Pagination.Item>
        );
      } else if (number === currentPage - 3 || number === currentPage + 3) {
        // Add ellipsis for hidden pages
        paginationItems.push(<Pagination.Ellipsis key={`ellipsis-${number}`} />);
      }
    }

    const handleCreate = () => {
        navigate('/settings/userform');
    }

    const handleCreateClient = () => {
        navigate('/settings/userclientform');
    }
        
  return (
    <div className='settings-container'>
        <h2>Mi Perfil</h2>
        <hr />
        <div className='profile' style={{color: '#602548', margin: '0 0 20px 0'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-gear" viewBox="0 0 16 16">
                    <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m.256 7a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382zM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0"/>
            </svg>
            <div>
                <h4>Nombre de Usuario</h4>
                <p style={{margin: '5px 0px', textTransform: 'capitalize'}}>{auth.username}</p>
            </div>
        </div>

        <h5>Información Personal</h5>
        <div style={{display: 'flex', margin: '10px 0 0 0', justifyContent: 'center'}} >
        <div className='first-col'>
            <p><strong>Nombre:</strong> {profile.auth_user?.first_name} </p>
            <p><strong>Apellido:</strong> {profile.auth_user?.last_name} </p>
            <p><strong>Correo:</strong> {profile.email} </p>
        </div>

        <div className='second-col'>
            <p><strong>Tipo de documento:</strong> {profile.document_type=== 'OTHER' ? 'Otro' : profile.document_type} </p>
            <p><strong>Documento:</strong> {profile.document_id} </p>
            <p><strong>Cargo:</strong> {displayRole} </p>
        </div>
        </div>

        <hr />

        {/* Unicamente para ADMINISTRADOR */}
        <PermissionWrapper feature="usuarios" action="read">
        <>
        <h2>Usuarios</h2>
        <Row>
            <Col style={{padding: '0 10px'}}>
            <Form inline  onSubmit={(e) => e.preventDefault()}>
                <Form.Control
                    type="search"
                    placeholder="Buscar por nombre de usuario"            
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                >
                </Form.Control>
            </Form>
            </Col>

            <PermissionWrapper feature="usuarios" action="create">
            <Col style={{display: 'flex', justifyContent: 'flex-end', padding: '0 30px'}}>
            <DropdownButton 
                id="dropdown-basic-button" 
                title="Crear Usuario"
            >
                <Dropdown.Item onClick={handleCreate}>Personal de la Empresa</Dropdown.Item>
                <Dropdown.Item onClick={handleCreateClient}>Cliente</Dropdown.Item>
            </DropdownButton>
            </Col>
            </PermissionWrapper>
        </Row>

        <Table striped bordered hover responsive>
        <thead>
            <tr>
                <th>ID</th>
                <th>Nombre de Usuario</th>
                <th>Correo</th>
                <th>Cargo</th>
            </tr>
        </thead>
        <tbody>
            {filteredUsers.map(user => (
                <tr key={user.user_id} onClick={() => handleRowClick(user.user_id)} style={{ cursor: 'pointer' }}>
                    <td>{user.user_id}</td>
                    <td>{user.auth_user.username}</td>
                    {/*
                    <td>
                    {user.auth_user.first_name && user.auth_user.last_name
                        ? `${user.auth_user.first_name} ${user.auth_user.last_name}`
                        : user.auth_user.username}
                    </td>
                    */}
                    <td>{user.email}</td>
                    <td>{role[user.role]}</td>
                </tr>
            ))}
        </tbody>
        </Table>
        <Pagination>
            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

            {paginationItems}

            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
        </>
        </PermissionWrapper>  
    </div>
  )
}

export default Profile