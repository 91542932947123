import React, { useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Col, Row } from 'react-bootstrap';
import '../../appstyles/ClientsCSS/ClientsForm.css'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';

// Validación de campos
const requiredField = Yup.string().required('Este campo es requerido');

export const clientSchema = Yup.object().shape({
  client_name: requiredField,
  document_type: requiredField,
  document_id: Yup.number()
  .required('Este campo es requerido')
  .typeError('Debe ser un número'),
  country: requiredField,
  city: requiredField,
  address: requiredField,
  phone: Yup.string()
  .required('Este campo es requerido')
  .matches(/^\+?\d+$/, 'Telefono no es válido'),
  web_page: Yup.string().notRequired(),
  email_billing: Yup.string()
  .required('Este campo es requerido')
  .email('Correo no es válido'),
  client_sector: requiredField,
  status: requiredField,
  priority: requiredField,
  credit_limit: Yup.number()
    .required('Este campo es requerido')
    .typeError('Debe ser un número')
    .integer('Debe ser un número entero'),
  credit_time: Yup.number()
    .required('Este campo es requerido')
    .typeError('Debe ser un número')
    .integer('Debe ser un número entero'),
  reviwed: Yup.boolean().notRequired(),
  approved: Yup.boolean().notRequired(),
  last_contacted: Yup.date().nullable(),
  name_commercial_contact: requiredField,
  email_commercial_contact: Yup.string()
    .required('Este campo es requerido')
    .email('Correo no es válido'),
  phone_commercial_contact: Yup.string()
    .required('Este campo es requerido')
    .matches(/^\+?\d+$/, 'Telefono no es válido'),
  name_financial_contact: Yup.string().notRequired(),
  email_financial_contact: Yup.string()
    .nullable()
    .email('Correo no es válido'),
  phone_financial_contact: Yup.string()
    .notRequired()
    .test(
      'is-valid-phone',
      'Telefono no es válido',
      (value) => {
        if (!value) return true; 
        return /^\+?\d+$/.test(value);
      }
    ),
  name_technical_contact: Yup.string().notRequired(),
  email_technical_contact: Yup.string().notRequired()
    .nullable()
    .email('Correo no es válido'),
  phone_technical_contact: Yup.string()
    .notRequired()
    .test(
      'is-valid-phone',
      'Telefono no es válido',
      (value) => {
        if (!value) return true; 
        return /^\+?\d+$/.test(value); 
      }
    ),
  name_logistic_contact: Yup.string().notRequired(),
  email_logistic_contact: Yup.string().notRequired()
    .nullable()
    .email('Correo no es válido'),
  phone_logistic_contact: Yup.string()
    .notRequired()
    .test(
      'is-valid-phone',
      'Telefono no es válido',
      (value) => {
        if (!value) return true; 
        return /^\+?\d+$/.test(value); 
      }
    ),
});

function ClientsForm() {
  const axiosInstance = useAxiosPrivate();
  const { clientId } = useParams();
  const navigate = useNavigate();
  
  const defaultValues = {
    document_type: 'NIT',
    status: 'Oportunidad de negocio',
    priority: 'M', 
    reviewed: false,
    approved: false,
  };

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
  resolver: yupResolver(clientSchema),
  defaultValues
  });

  useEffect(() => {
    if (clientId) {
      // Fetch client data for editing
      const fetchClientData = async () => {
        try {
          const response = await axiosInstance.get(`/api/client/${clientId}/`);
          reset(response.data);
        } catch (error) {
          console.error('Error fetching client data:', error);
        }
      };

      fetchClientData(clientId);
    }
  }, [clientId, reset]);

  const onSubmit = async (data) => {

    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== undefined && value !== '')
    );
    
    try {
      const token = localStorage.getItem('accessToken');
    
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      };

      if (clientId) {
        // Update existing client
        await axiosInstance.put(`api/client/${clientId}/`, filteredData, config); 

        navigate(`/client/details/${clientId}`)

      } else {
        // Create new client
        await axiosInstance.post('/api/client/', filteredData, config);

        reset();
        setTimeout(() => {
          navigate(`/clientslist`);
        }, 1000); 
      }

    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  // Función para enviar los datos del formulario 
  return (
    <div className='maestros-form'>
    <h2>{clientId ? 'Editar Cliente' : 'Crear Cliente'}</h2> 
    <Form onSubmit={handleSubmit(onSubmit)}>
    <hr />
    <h5>Información General</h5>
      <Row>
        {/* Primera Columna*/}
        <Col>

        <Form.Group controlId="client_name">
          <Form.Label>Razón social</Form.Label>
          <Form.Control type="text" {...register('client_name')} isInvalid={!!errors.client_name}/>
          <Form.Control.Feedback type="invalid">
                {errors.client_name?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="document_type">
          <Form.Label>Tipo de documento</Form.Label>
          <Form.Select aria-label="Default select example" {...register('document_type')} isInvalid={!!errors.document_type}>
              <option value="NIT">NIT</option>
              <option value="CC">Cedula de Ciudadania (CC)</option>
              <option value="TAX ID">Tax ID</option>
              <option value="Otro">Otro</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
                  {errors.document_type?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="document_id">
          <Form.Label>Documento</Form.Label>
          <Form.Control type="text" {...register('document_id')} isInvalid={!!errors.document_id} />
          <Form.Control.Feedback type="invalid">
                {errors.document_id?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="country">
            <Form.Label>País</Form.Label>
            <Form.Control type="text" {...register('country')} isInvalid={!!errors.country} />
            <Form.Control.Feedback type="invalid">
                  {errors.country?.message}
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="city">
          <Form.Label>Ciudad</Form.Label>
          <Form.Control type="text" {...register('city')} isInvalid={!!errors.city} />
          <Form.Control.Feedback type="invalid">
                  {errors.city?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="address">
          <Form.Label>Dirección</Form.Label>
          <Form.Control type="text" {...register('address')} isInvalid={!!errors.address}/>
          <Form.Control.Feedback type="invalid">
                  {errors.address?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="phone">
          <Form.Label>Teléfono</Form.Label>
          <Form.Control type="text" {...register('phone')} isInvalid={!!errors.phone}/>
          <Form.Control.Feedback type="invalid">
                  {errors.phone?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="web_page">
          <Form.Label>Página web (opcional)</Form.Label>
          <Form.Control type="text" {...register('web_page')} isInvalid={!!errors.web_page}/>
          <Form.Control.Feedback type="invalid">
                  {errors.web_page?.message}
          </Form.Control.Feedback>
        </Form.Group>

        </Col>
        {/* Segunda Columna*/}
        <Col>

        <Form.Group controlId="email_billing">
          <Form.Label>Correo envío de facturas</Form.Label>
          <Form.Control type="email" {...register('email_billing')} isInvalid={!!errors.email_billing} />
          <Form.Control.Feedback type="invalid">
                  {errors.email_billing?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="client_sector">
          <Form.Label>Sector de cliente</Form.Label>
          <Form.Select aria-label="Default select example" {...register('client_sector')} isInvalid={!!errors.client_sector}>
              <option value="Animal">Animal</option>
              <option value="Farmacéutica">Farmacéutica</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
                  {errors.client_sector?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="status">
          <Form.Label>Estado de cliente</Form.Label>
          <Form.Select aria-label="Default select example" {...register('status')} isInvalid={!!errors.status}>
              <option value="Frecuente">Frecuente</option>
              <option value="Ocasional">Ocasional</option>
              <option value="Oportunidad de negocio">Oportunidad de negocio</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
                  {errors.status?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="priority">
          <Form.Label>Prioridad</Form.Label>
          <Form.Select aria-label="Default select example" {...register('priority')} isInvalid={!!errors.priority}>
              <option value="H">Alta</option>
              <option value="M">Media</option>
              <option value="L">Baja</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
                  {errors.priority?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="credit_limit">
          <Form.Label>Cupo de crédito (COP)</Form.Label>
          <Form.Control 
            type="number" 
            isInvalid={!!errors.credit_limit}
            inputMode="decimal"
            step="any" 
            min='0'
            pattern="[0-9]*[.,]?[0-9]*" 
            onKeyDown={(e) => e.key === 'ArrowUp' || e.key === 'ArrowDown' ? e.preventDefault() : null}
            {...register('credit_limit')} 
          />
          <Form.Control.Feedback type="invalid">
                  {errors.credit_limit?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="credit_time">
          <Form.Label>Período de pago (días)</Form.Label>
          <Form.Control 
            type="number" 
            min='0'
            isInvalid={!!errors.credit_time}
            onKeyDown={(e) => e.key === 'ArrowUp' || e.key === 'ArrowDown' ? e.preventDefault() : null} 
            {...register('credit_time')} 
          />
          <Form.Control.Feedback type="invalid">
                  {errors.credit_time?.message}
          </Form.Control.Feedback>
        </Form.Group>


        {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Group controlId="reviewed">
          <Form.Check
            inline
            label="Revisión por área comercial"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
            {...register('reviewed')}
          />
          </Form.Group>

          <Form.Group controlId="approved">
          <Form.Check
            inline
            label="Aprobado por área financiera"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
            {...register('approved')}
          />
          </Form.Group>

          
        <Form.Group controlId="last_contacted">
          <Form.Label>Última vez contactado</Form.Label>
          <Form.Control placeholder="Disabled input" disabled={!clientId}  type="date" {...register('last_contacted')} isInvalid={!!errors.last_contacted} />
          <Form.Control.Feedback type="invalid">
                  {errors.last_contacted?.message}
          </Form.Control.Feedback>
        </Form.Group>

        </div>
        ))}
        </Col>
      </Row>
    <hr />
    <h5>Contactos</h5>
    <Row>
      <Col>
      <h5 style={{fontWeight: '700', color: '#07514F'}}>Área Comercial</h5>
      <Form.Group controlId="name_commercial_contact">
        <Form.Label>Nombre área comercial*</Form.Label>
        <Form.Control type="text" {...register('name_commercial_contact')} isInvalid={!!errors.name_commercial_contact} />
        <Form.Control.Feedback type="invalid">
          {errors.name_commercial_contact?.message}
        </Form.Control.Feedback>
      </Form.Group>
      
      <Form.Group controlId="email_commercial_contact">
        <Form.Label>Correo electrónico*</Form.Label>
        <Form.Control type="email" {...register('email_commercial_contact')} isInvalid={!!errors.email_commercial_contact}/>
        <Form.Control.Feedback type="invalid">
          {errors.email_commercial_contact?.message}
        </Form.Control.Feedback>
      </Form.Group>  

      <Form.Group controlId="phone_commercial_contact">
        <Form.Label>Teléfono*</Form.Label>
        <Form.Control type="text" {...register('phone_commercial_contact')} isInvalid={!!errors.phone_commercial_contact} />
        <Form.Control.Feedback type="invalid">
          {errors.phone_commercial_contact?.message}
        </Form.Control.Feedback>
      </Form.Group>
      </Col>
      
      <Col>
      <h5 style={{fontWeight: '700', color: '#07514F'}}>Área Financiera</h5>
      <Form.Group controlId="name_financial_contact" >
        <Form.Label>Nombre área financiera</Form.Label>
        <Form.Control type="text" {...register('name_financial_contact')}/>
      </Form.Group>

      <Form.Group controlId="email_financial_contact">
        <Form.Label>Correo electrónico</Form.Label>
        <Form.Control type="email" {...register('email_financial_contact')} isInvalid={!!errors.email_financial_contact}/>
        <Form.Control.Feedback type="invalid">
          {errors.email_financial_contact?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="phone_financial_contact" >
        <Form.Label>Teléfono</Form.Label>
        <Form.Control type="text" {...register('phone_financial_contact')} isInvalid={!!errors.phone_financial_contact} />
        <Form.Control.Feedback type="invalid">
          {errors.phone_financial_contact?.message}
        </Form.Control.Feedback>
      </Form.Group> 
      </Col>
    </Row>
    
    <hr />

    <Row>
      <Col>
      <h5 style={{fontWeight: '700', color: '#07514F'}}>Área Técnica</h5>
      <Form.Group controlId='name_technical_contact'>
        <Form.Label>Nombre área técnica</Form.Label>
        <Form.Control type="text" {...register('name_technical_contact')}/>
      </Form.Group>

      <Form.Group controlId="email_technical_contact">
        <Form.Label>Correo electrónico</Form.Label>
        <Form.Control type="email" {...register('email_technical_contact')} isInvalid={!!errors.email_technical_contact}/>
        <Form.Control.Feedback type="invalid">
            {errors.email_technical_contact?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId='phone_technical_contact'>
        <Form.Label>Teléfono</Form.Label>
        <Form.Control type="text" {...register('phone_technical_contact')} isInvalid={!!errors.phone_technical_contact} />
        <Form.Control.Feedback type="invalid">
            {errors.phone_technical_contact?.message}
        </Form.Control.Feedback>
      </Form.Group>
      </Col>

      <Col>
        <h5 style={{fontWeight: '700', color: '#07514F'}}>Área Logística</h5>
        <Form.Group controlId='name_logistic_contact'>
          <Form.Label>Nombre área logística</Form.Label>
          <Form.Control type="text" {...register('name_logistic_contact')}/>
        </Form.Group>

        <Form.Group controlId="email_logistic_contact">
          <Form.Label>Correo electrónico</Form.Label>
          <Form.Control type="email" {...register('email_logistic_contact')} isInvalid={!!errors.email_logistic_contact}/>
          <Form.Control.Feedback type="invalid">
              {errors.email_logistic_contact?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='phone_logistic_contact'>
          <Form.Label>Teléfono</Form.Label>
          <Form.Control type="text" {...register('phone_logistic_contact')} isInvalid={!!errors.phone_logistic_contact} />
          <Form.Control.Feedback type="invalid">
              {errors.phone_logistic_contact?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row>
    <Button variant="primary" type="submit">
      {clientId ? 'Guardar Cambios' : 'Crear Cliente'}
    </Button>
    </Form>
    </div> 
  )
}

export default ClientsForm