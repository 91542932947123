import React, { useState, useEffect, useMemo } from 'react';
import { Table, Pagination, Form, Spinner } from 'react-bootstrap';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';

import '../../appstyles/ClientsCSS/ClientsList.css';

const CostClient = ({ selectedClient, handleSelectClient }) => {
    const axiosInstance = useAxiosPrivate();
    const [loading, setLoading] = useState(false); 
    //fetchClients
    const [clients, setClients] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState('');
    const rowsPerPage = 10;

  useEffect(() => {
    fetchClients(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, search]);

  const fetchClients = (page, searchQuery) => {
    const url = searchQuery
      ? `api/client/?page=${page}&size=${rowsPerPage}&client_name=${searchQuery}`
      : `api/client/?page=${page}&size=${rowsPerPage}`;

      setLoading(true);

      axiosInstance.get(url)
      .then(response => {
        setClients(response.data);
        setTotalPages(response.data.totalPages);
      })
      .catch(error => {
        console.log('Failed to fetch clients', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filteredClients = useMemo(() => {
    if (!clients || !Array.isArray(clients.data)) {
      console.error('clients.data is not an array:', clients);
      return [];
    }
    
    return clients.data.filter(client => {
      return client.client_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  });
  }, [clients, search]);

   const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginationItems = [];
  for (let number = 1; number <= totalPages; number++) {
    if (number === 1 || number === totalPages || (number >= currentPage - 2 && number <= currentPage + 2)) {
      paginationItems.push(
        <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
          {number}
        </Pagination.Item>
      );
    } else if (number === currentPage - 3 || number === currentPage + 3) {
      paginationItems.push(<Pagination.Ellipsis key={`ellipsis-${number}`} />);
    }
  }  

  if (loading) {
    return(
    <div className='loading-container'>
      <p style={{fontFamily: 'Roboto, sans-serif'}}>Cargando...</p>
      <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem'}} />
    </div>
    );
  }

  return (
    <div className='maestros-list' style={{padding: '15px 0'}}>
    <Form inline style={{ width: '50%' }} onSubmit={(e) => e.preventDefault()}>
        <Form.Control
          type="search"
          placeholder="Buscar por nombre de cliente"            
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        >
        </Form.Control>
    </Form>
    <Table striped bordered hover responsive
        style={{margin: '0px'}}
    >
      <thead>
        <tr>
            <th>ID</th>
            <th>Nombre Cliente</th>
            <th>Sector</th>
            <th>País</th>
            <th>Seleccionar</th>
        </tr>
      </thead>
      <tbody>
        {filteredClients.map(client => (
          <tr key={client.client_id}>
            <td>{client.client_id}</td>
            <td>{client.client_name}</td>
            <td>{client.client_sector}</td>
            <td>{client.country}</td>
            <td 
                style={{
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    border: 'none', 
                }}
            >
            <Form.Check 
                onChange={() => handleSelectClient(client)}
                checked={selectedClient === client.client_id}
                disabled={selectedClient !== null && selectedClient !== client.client_id}
                style={{pointer: 'cursor'}}
            />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
      <Pagination
        style={{marginTop: '10px'}}
      >
        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

        {paginationItems}

        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
      </Pagination>
    </div>
  );
};

export default CostClient;