import React, {useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import { Spinner, Modal, Button, Table, Form, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PermissionWrapper from '../../context/PermissionWrapper';
import '../../appstyles/ClientsCSS/ClientsDetails.css'
import '../../appstyles/ProductsCSS/ProductsDetail.css'

function ProductsDetails() {
  const axiosInstance = useAxiosPrivate();
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [packingDetails, setPackingDetails] = useState([]);
  const navigate = useNavigate();
  const [showDelete, setShowDelete] = useState(false);
  const [showPacking, setShowPacking] = useState(false);
  const [showPackagingType, setShowPackagingType] = useState(false);

  const [options, setOptions] = useState({
    packaging_type: [],
  });

  const schema = yup.object().shape({
    packaging_type: yup.string().notRequired(),
    packaging_size: yup.string().notRequired(),
    unity: yup.string().notRequired(),
    value: yup.string().notRequired(),
  });

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema),  
  });

  const fetchOptions = async () => {
    try { 
      const response = await axiosInstance.get('api/packaging-type/');
      setOptions({ ...options, packaging_type: response.data });
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };

  useEffect(() => {
    fetchOptions();
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data) => {
    axiosInstance.post(`api/product-detail/`, {
      product_id: productId,
      ...data,
    })
      .then(response => {
        setPackingDetails([...packingDetails, response.data]);
        handleClosePacking();
      })
      .catch(error => {
        console.error('Failed to save packing detail', error);
      });
  };

  useEffect(() => {
  console.log('Fetching product details for ID:', productId);
  axiosInstance.get(`api/product/${productId}/`)
    .then(response => {
      console.log('Response data:', response.data);
      setProduct(response.data);
      fetchPacking(response.data.product_id); // Fetch packing details
    })
    .catch(error => {
      console.error('Failed to fetch product details', error);
    });
  }, [productId]);

  const handleShowDelete = () => setShowDelete(true);
  const handleCloseDelete = () => setShowDelete(false);

  const handleClosePacking = () => setShowPacking(false);
  const handleShowPacking = () => setShowPacking(true);

  const confirmDelete = () => {
    axiosInstance.delete(`api/product/${productId}/`)
      .then(response => {
        navigate('/productslist');
      })
      .catch(error => {
        console.error('Failed to delete product', error);
      });
  };

  const fetchPacking = (product_id) => {
    axiosInstance.get(`api/product-detail/?product_id=${product_id}`)
      .then(response => {
        setPackingDetails(response.data);
      })
      .catch(error => {
        console.log('Failed to fetch packing details', error);
      });
  };

  const deletePacking = async(id) => {
    try{
      const response = await axiosInstance.delete(`api/product-detail/${id}/`);
      console.log('Response:', response.data);
      setPackingDetails(packingDetails.filter(detail => detail.id !== id));
    } catch (error) {
      console.error('Error deleting packing detail:', error);
    }
  };

  const handleShowPackagingType = () => setShowPackagingType(true);

  const createPackagingType = async (data) => {
    try {
      const response = await axiosInstance.post('/api/packaging-type/', { value: data.value });
      console.log('Response:', response.data);
      const newPackagingType = response.data;

    setOptions(prevOptions => ({
      ...prevOptions,
      packaging_type: [...prevOptions.packaging_type, newPackagingType]
    }));
    reset();

    setTimeout(() => {
      setShowPackagingType(false);
    }, 2000);
    } catch (error) {
      console.error('Error creating packaging type:', error);
    }
  };
  
  
  if (!product) {
    return(
    <div className='loading-container'>
      <p style={{fontFamily: 'Roboto, sans-serif'}}>Cargando...</p>
      <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem'}} />
    </div>
    );
  }

  const handleEditClick = () => {
    navigate(`/productsform/${productId}`); // Adjust the path as needed
  };

  if (!product) {
    return(
    <div className='loading-container'>
      <p style={{fontFamily: 'Roboto, sans-serif'}}>Cargando...</p>
      <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem'}} />
    </div>
    );
  }

  return (
    <div className='maestros-details'>

      <h2>Detalles de Producto</h2>
      <hr />
      <div className='maestros-details-actions' style={{display: 'flex', flexDirection: 'row'}}>
        <h4>{product.product_name}</h4>

        <PermissionWrapper feature="producto" action="update">
          <button 
            className='btn btn-primary' 
            style={{backgroundColor: '#025C5A', borderColor: '#025C5A '}}
            onClick={handleEditClick}
          > 
            Editar
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
          </svg>
          </button>
        </PermissionWrapper>

        <PermissionWrapper feature="producto" action="delete">
          <button className='btn btn-danger' onClick={handleShowDelete}>
            Eliminar
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
          </svg>
          </button>
        </PermissionWrapper>
        
        <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
            </svg>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Estas a punto de eliminar a {product.product_name} </h5>
          <p>¿Estás seguro de que lo deseas eliminar? Esta acción no podrá ser revertida</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete}>
            Cerrar
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Eliminar
          </Button> 
        </Modal.Footer>
        </Modal>


      </div>

      <div style={{display: 'flex', flexDirection: 'row'}}>
      <div className='first-col'>
        <p><strong>ID:</strong> {product.product_id} </p>
        <p><strong>Fabricante:</strong> {product.manufacturer} </p>
        <p><strong>País:</strong> {product.country} </p>
        <p><strong>Categoría:</strong> {product.product_category} </p>
        <p><strong>Subcategoría:</strong> {product.product_subcategory} </p>
      </div>  
      <div className='second-col'>
        <p><strong>Presentación:</strong> {product.product_presentation} </p>
        <p><strong>Calidad:</strong> {product.product_quality} </p>
        <p><strong>Posición arancelaria:</strong> {product.tariff_code} </p>
        <p><strong>Arancel (%):</strong> {product.variable_tariff} </p>
        <p><strong>IVA (%):</strong> {product.vat} </p>
      </div>
      </div>

      <hr />
      <div className='empaque'>
        <h4>Empaque</h4>
        <Button variant='primary' onClick={handleShowPacking}>
            Añadir Empaque
        </Button>
        <Table striped bordered hover responsive>
        <thead>
            <tr>
            <th>Tipo de Empaque</th>
            <th>Tamaño</th>
            <th>Unidad</th>
            <th>Eliminar</th>
            </tr>
        </thead>
        <tbody>
            {packingDetails.map(detail => (
            <tr key={detail.id}>
                <td>{detail.packaging_type}</td>
                <td>{detail.packaging_size}</td>
                <td>{detail.unity}</td>
                <td style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <Button variant="outline-danger" onClick={() => deletePacking(detail.id)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                  </svg>
                  </Button>
                </td>
            </tr>
            ))}
        </tbody>
        </Table>

        <Button 
        variant="secondary" 
        style={{marginTop: '20px', width: '20%'}}
        onClick={() => navigate(`/productslist`)}
        >
          Volver
      </Button>
    </div>
    <Modal show={showPacking} onHide={handleClosePacking}>
        <Modal.Header closeButton>
          <Modal.Title> <strong>Añadir empaque para {product.product_name}</strong> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
            <Form.Label>Tipo de empaque</Form.Label>
            <InputGroup>
            <Form.Select 
                id='packaging_type'
                aria-label="Default select example" {...register('packaging_type')} isInvalid={!!errors.packaging_type}>
                {options.packaging_type.map((opt) => (
                <option key={opt.id} value={opt.value}>{opt.value}</option>
                ))}
            </Form.Select>
            <Button variant="outline-primary" onClick={handleShowPackagingType}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
            </svg>
            </Button>
            </InputGroup>
            </Form.Group>
            
            {showPackagingType && (
            <Form.Group>
              <Form.Label>Crear tipo de empaque</Form.Label>
              <InputGroup>
              <Form.Control
                type="text"
                placeholder=""
                {...register('value')}
                isInvalid={!!errors.value}
              />
              <Button variant="primary" onClick={handleSubmit(createPackagingType)}>
                Crear
              </Button>
              </InputGroup>
            </Form.Group>
            )}

            <Form.Group>
              <Form.Label>Tamaño</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                {...register('packaging_size')}
                isInvalid={!!errors.packaging_size}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Unidad</Form.Label>
              <Form.Select
                type="text"
                placeholder=""
                {...register('unity')}
                isInvalid={!!errors.unity}
              >
                <option value="KG">Kilogramos</option>
                <option value="G">Gramos</option>
                <option value="TON">Tonelada</option>
                <option value="TON_S">Tonelada corta</option>
                <option value="TON_L">Tonelada larga</option>
                <option value="LB">Libra</option>
                <option value="OZ">Onzas</option>
                <option value="L">Litro</option>
                <option value="ML">Mililtro</option>
                <option value="GAL_UK">Galón UK</option>
                <option value="GAL_US">Galón US</option>
                <option value="FCL">FCL</option>
              </Form.Select>
            </Form.Group>

            <Button variant="primary" type="submit" style={{backgroundColor: '#025C5A', borderColor: '#025C5A '}}>
              Guardar
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ProductsDetails