import React, {useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import { Spinner, Modal, Button, Row, Form } from 'react-bootstrap';
import '../../appstyles/ClientsCSS/ClientsDetails.css'
import '../../appstyles/QuotesCSS/OrderDetails.css'
import ClientPDF from './ClientPDF';
import SupplierPDF from './SupplierPDF';
import PermissionWrapper from '../../context/PermissionWrapper';

const unitNames = {
    KG: 'Kilogramos',
    G: 'Gramos',
    TON: 'Tonelada',
    TON_S: 'Tonelada corta',
    TON_L: 'Tonelada larga',
    LB: 'Libra',
    OZ: 'Onzas',
    L: 'Litro',
    ML: 'Mililitro',
    GAL_UK: 'Galón UK',
    GAL_US: 'Galón US',
    FCL: 'FCL',
  };

function    OrderDetails() {
  const axiosInstance = useAxiosPrivate();
  const { quoteId } = useParams();
  const [quote, setQuote] = useState(null);
  const navigate = useNavigate();
  const [feedback, setFeedback] = useState('');
  //Toast
  const [showApprove, setShowApprove] = useState(false);
  const [showDecline, setShowDecline] = useState(false);

  const handleCloseApprove = () => setShowApprove(false);
  const handleShowApprove = () => setShowApprove(true);

  const handleCloseDecline = () => setShowDecline(false);
  const handleShowDecline = () => setShowDecline(true);

  useEffect(() => {
    axiosInstance.get(`api/quote/${quoteId}/`)
      .then(response => {
        setQuote(response.data);
      })
      .catch(error => {
        console.error('Failed to fetch quote details', error);
      });
  }, [quoteId, axiosInstance]);

  const declineQuote = () => {
    axiosInstance.post(`api/quote/approval/`, 
      {
        status: 'Rechazado',
        quote_id: +quoteId,
        feedback: feedback,
      }
    )
      .then(() => {
        window.location.reload();
      })
      .catch(error => {
        console.error('Failed to decline quote', error);
      });
  };

  const approveQuote = () => {
    axiosInstance.post(`api/quote/approval/`,
      {
        status: 'Aprobado',
        quote_id: +quoteId,
        feedback: feedback,
      }
    )
      .then(() => {
        window.location.reload();
      })
      .catch(error => {
        console.error('Failed to approve quote', error);
      });
  };

  const handleEditClick = () => {
    navigate(`/consignee/${quoteId}`);
  };

  if (!quote) {
    return(
    <div className='loading-container'>
      <p style={{fontFamily: 'Roboto, sans-serif'}}>Cargando...</p>
      <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem'}} />
    </div>
    );
  }

  return (
    <div className='maestros-details'>

        <h2>Detalles de Cotización</h2>
        <hr />
        <div className='orders-details-actions'>
        <h4>Cotización No. {quote.quote_id}</h4>

        <div className='orders-actions'>
        <ClientPDF/>

        <PermissionWrapper feature="cotizaciones" action="create">
          <SupplierPDF/>
        </PermissionWrapper>

        <button 
        className='btn btn-primary' 
        style={{backgroundColor: '#025C5A', borderColor: '#025C5A'}}
        onClick={handleEditClick}
        > 
            Consignatario
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16" style={{marginLeft: '10px'}}> 
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
            </svg>
        </button>
        </div>
        
        </div>

        <h4 style={{fontWeight: '700', color: '#07514F'}}>{quote.products.length > 1 ? 'Productos' : 'Producto'}</h4>
        {quote.products.map(product => (
        <Row key={product.id}>
        <div className='first-col'>
            <p><strong>Id del producto:</strong> {product.product_id}</p>
            <p><strong>Nombre del producto:</strong> {product.product_name} </p>
            <p><strong>Fabricante:</strong> {product.product_manufacturer}</p>
            <p>
              <strong>Empaque:</strong>
              {product.product_detail 
              ? `${product.product_detail.packaging_size} ${unitNames[product.product_detail.unity]} en ${product.packaging_type}` 
              :'N/A'
              }   
            </p>
        </div>

        <div className='second-col'>
            <p><strong>Cantidad:</strong> {product.quantity}</p>
            <p><strong>Unidad:</strong> {unitNames[product.quantity_unit]}</p>
            <p><strong>Precio Unitario:</strong> {product.unit_price}</p>
            <p><strong>Moneda:</strong> {quote.currency}</p>
        </div>
        <hr />
        </Row>
        ))}

        <h4 style={{fontWeight: '700', color: '#07514F'}}>Cotización</h4>
        <div style={{display: 'flex', flexDirection: 'row'}}>
        <div className='first-col'>
            <p><strong>ID del Cliente:</strong> {quote.client_id} </p>
            <p><strong>Nombre del Cliente:</strong> {quote.client_name} </p>
            <p><strong>ID del Proveedor:</strong> {quote.supplier_id} </p>
            <p><strong>Nombre del Proveedor:</strong> {quote.supplier_name} </p>
            <p><strong>Incoterm:</strong> {quote.incoterm} </p>
            <p><strong>Terminos de pago (días):</strong> {quote.payment_terms} </p>
            <p><strong>Tipo de compra:</strong> {quote.buy_type} </p>
            <p><strong>Estado: </strong> 
            <span style={{ color: quote.status === 'Aprobado' ? 'green' : quote.status === 'Rechazado' ? 'red' : 'black' }}>
              {quote.status}
            </span>
            </p>
        </div>  

        <div className='second-col'>
            <p><strong>Consecutivo:</strong> {quote.consecutive} </p>
            <p><strong>Creación de cotización:</strong> {quote.order_date} </p>
            <p><strong>Fecha de llegada solicitada:</strong> {quote.requested_delivery_date} </p>
            <p><strong>Fecha solicitada:</strong> {quote.requested_date} </p>
            <p><strong>Ciudad de llegada:</strong> {quote.arrival_city} </p>
            <p><strong>Detalles de entrega:</strong> {quote.delivery_details ? quote.delivery_details : 'N/A'} </p>
            <p><strong>Feedback:</strong> {quote.feedback} </p>
        </div>
        </div>

        <hr />
        <h4 style={{fontWeight: '700', color: '#07514F'}}>Consignatario</h4>
        <div style={{display: 'flex', flexDirection: 'row'}}>
        <div className='first-col'>
            <p><strong>Nombre:</strong> {quote.consignee_name} </p>
            <p><strong>Tipo de documento:</strong> {quote.consignee_document_type} </p>
            <p><strong>Documento:</strong> {quote.consignee_document_id} </p>
        </div>  

        <div className='second-col'>
            <p><strong>Dirección:</strong> {quote.consignee_address} </p>
            <p><strong>Instrucciones:</strong> {quote.instructions} </p>
        </div>
        </div>

        <Modal show={showApprove} onHide={handleCloseApprove}>
        <Modal.Header closeButton>
          <Modal.Title>
            Aprobar Cotización
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Escribe una observación para ésta cotización que será <strong>aprobada</strong></p>

          <Form.Group controlId="feedback">
          <Form.Label>Feedback</Form.Label>
          <Form.Control 
            type="textarea" 
            value={feedback} 
            onChange={(e) => setFeedback(e.target.value)} 
          />
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseApprove}>
            Cerrar
          </Button>
          <Button variant="success" onClick={approveQuote}>
            Aprobar
          </Button>
        </Modal.Footer>
        </Modal>

        <Modal show={showDecline} onHide={handleCloseDecline}>
        <Modal.Header closeButton>
          <Modal.Title>
            Rechazar Cotización
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p>Escribe una observación para ésta cotización que será <strong>rechazada</strong></p>

        <Form.Group controlId="feedback">
        <Form.Label>Feedback</Form.Label>
        <Form.Control 
          type="textarea" 
          value={feedback} 
          onChange={(e) => setFeedback(e.target.value)} 
        />
        </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDecline}>
            Cerrar
          </Button>
          <Button variant="danger" onClick={declineQuote}>
            Rechazar
          </Button>
        </Modal.Footer>
        </Modal>

        {/*Botones*/}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '5px',
          }}
        >
        <Button 
          variant="success" 
          style={{marginTop: '20px', width: '15%'}}
          onClick={handleShowApprove}
          >
            Aprobar
        </Button>
        
        <Button 
            variant='danger'
            style={{marginTop: '20px', width: '15%'}}
            onClick={handleShowDecline}
        >
          Rechazar
        </Button>
        </div>

        <Button 
          variant="secondary" 
          style={{marginTop: '20px', width: '20%'}}
          onClick={() => navigate(`/quoteslist`)}
          >
            Volver
        </Button>

    </div>
  )
}

export default OrderDetails