import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Col, Row, Spinner } from 'react-bootstrap';
import '../../appstyles/ClientsCSS/ClientsForm.css'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';

// Validación de campos del consignatario

export const clientSchema = Yup.object().shape({
  consignee_name: Yup.string().nullable(),
  consignee_document_type: Yup.string().nullable(),
  consignee_document_id: Yup.string().nullable(),
  consignee_address: Yup.string().nullable(),
  instructions: Yup.string().nullable(),
  });

function ConsigneeForm() {
    const axiosInstance = useAxiosPrivate();
    const navigate = useNavigate();
    const { quoteId } = useParams();
    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, reset } = useForm({
    resolver: yupResolver(clientSchema),
    defaultValues: {
        consignee_document_type: '',
    }
    });

    useEffect(() => {
        if (quoteId) {
        setLoading(true);
        const fetchConsigneeData = async () => {
            try {
            const response = await axiosInstance.get(`api/quote/${quoteId}/`);
            reset(response.data);

            } catch (error) {
            console.error('Error fetching client data:', error);

            } finally {
            setLoading(false);

            }
        };

        fetchConsigneeData(quoteId);
        }
    }, [quoteId, reset]);

    const onSubmit = async (data) => {
        try {
            setLoading(true);
            await axiosInstance.post(`api/quote/create-order/`,
                {
                    ...data, 
                    quote_id: +quoteId
                });

            navigate(`/quote/details/${quoteId}`);

        } catch (error) {
        console.error('Error submitting form:', error);
        } finally {
        setLoading(false);
        }
    };

    if (loading) {
        return(
        <div className='loading-container'>
          <p style={{fontFamily: 'Roboto, sans-serif'}}>Cargando...</p>
          <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem'}} />
        </div>
        );
    }

    return (
        <div className='maestros-form'>
        <h2>Consignatario</h2> 
        <Form onSubmit={handleSubmit(onSubmit)}>
        <hr />
        <Row>
            {/* Primera Columna*/}
            <Col>
            <Form.Group controlId="consignee_name">
            <Form.Label>Nombre</Form.Label>
            <Form.Control type="text" {...register('consignee_name')}/>
            </Form.Group>

            <Form.Group controlId="consignee_document_type">
            <Form.Label>Tipo de documento</Form.Label>
            <Form.Select aria-label="Default select example" {...register('consignee_document_type')}>
                <option value="NIT">NIT</option>
                <option value="CC">Cedula de Ciudadania (CC)</option>
                <option value="TAX ID">Tax ID</option>
                <option value="Otro">Otro</option>
            </Form.Select>
            </Form.Group>

            <Form.Group controlId="consignee_document_id">
            <Form.Label>Documento</Form.Label>
            <Form.Control type="text" {...register('consignee_document_id')} />
            </Form.Group>
            </Col>

            {/* Segunda Columna*/}
            <Col>
            <Form.Group controlId="consignee_address">
                <Form.Label>Dirección</Form.Label>
                <Form.Control type="text" {...register('consignee_address')}/>
            </Form.Group>

            <Form.Group controlId='instructions'>
            <Form.Label>Instrucciones</Form.Label>
            <Form.Control 
                as="textarea" 
                rows={4} 
                placeholder='Mensaje...' 
                {...register('instructions')}
            />
            </Form.Group>
            </Col>
        </Row>
        <hr />

        <Button variant="primary" type="submit">
            Guardar
        </Button>
        </Form>
        </div> 
    )
    }

export default ConsigneeForm