import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Button, Table, Form, Pagination, Spinner } from 'react-bootstrap';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import '../../appstyles/ClientsCSS/ClientsList.css';

const RequestProductModal = ({ showModal, closeModal, handleSelectProduct, selectedProduct, selectedProducts }) => {
  const axiosInstance = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const rowsPerPage = 10;

  useEffect(() => {
    fetchProduct(currentPage, search);
  }, [currentPage, search]);

  const fetchProduct = (page, searchQuery) => {
    const url = searchQuery
        ? `api/product/?page=${page}&size=${rowsPerPage}&product_name=${searchQuery}`
        : `api/product/?page=${page}&size=${rowsPerPage}`;

    setLoading(true);

    axiosInstance.get(url)
        .then(response => {
        setProduct(response.data);
        setTotalPages(response.data.totalPages);
        })
        .catch(error => {
        console.log('Failed to fetch product', error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const filteredProduct = useMemo(() => {
        if (!product || !Array.isArray(product.data)) {
            console.error('product.data is not an array:', product);
            return [];
        }
    
        return product.data.filter(product => {
            return product.product_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        });
    }, [product, search]);
        
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    
    const paginationItems = [];
    for (let number = 1; number <= totalPages; number++) {
        if (number === 1 || number === totalPages || (number >= currentPage - 2 && number <= currentPage + 2)) {
        paginationItems.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
            {number}
            </Pagination.Item>
        );
        } else if (number === currentPage - 3 || number === currentPage + 3) {
        paginationItems.push(<Pagination.Ellipsis key={`ellipsis-${number}`} />);
        }
    }


  return (
    <Modal show={showModal} onHide={closeModal} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title><strong>Seleccionar Producto</strong></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='maestros-list'>
        {/* Campo de búsqueda */}
        <Form inline style={{ width: '50%' }} onSubmit={(e) => e.preventDefault()}>
            <Form.Control
            type="search"
            placeholder="Buscar por nombre de producto"            
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            >
            </Form.Control>
        </Form>

        {/* Tabla de productos */}
        {loading ? (
            <div className='loading-container'>
            <p style={{fontFamily: 'Roboto, sans-serif'}}>Cargando...</p>
            <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem'}} />
          </div>
        ) : (
        <Table striped bordered hover responsive
            style={{margin: '0px', height: '200px', overflowY: 'auto'}}
        >
        <thead>
            <tr>
                <th>ID</th>
                <th>Nombre Producto</th>
                <th>Fabricante</th>
                <th>Categoría</th>
                <th>Subcategoría</th>
                <th>Seleccionar</th>
            </tr>
        </thead>
        <tbody>
            {filteredProduct.map(product => (
            <tr key={product.product_id}>
                <td>{product.product_id}</td>
                <td>{product.product_name}</td>
                <td>{product.manufacturer}</td>
                <td>{product.product_category}</td>
                <td>{product.product_subcategory}</td>
                <td 
                    style={{
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        border: 'none', 
                        height: '100%'
                    }}
                >
                <Form.Check 
                    onChange={() => handleSelectProduct(product)}
                    checked={selectedProducts.some(item => item.product_id === product.product_id)} 
                />
                </td>
            </tr>
            ))}
        </tbody>
        </Table>
        )}

        {/* Paginación */}
        <Pagination
            style={{marginTop: '10px'}}
        >
            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

            {paginationItems}

            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RequestProductModal;
