import React, { useState, useEffect, useMemo } from 'react';
import { Table, Pagination, Form, InputGroup, Row, Col, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import ExcelSuppliers from './ExcelSuppliers';
import '../../appstyles/ClientsCSS/ClientsList.css';

const SuppliersList = () => {
  const axiosInstance = useAxiosPrivate();
  const [suppliers, setSuppliers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false)
  const rowsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    fetchSuppliers(currentPage, search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, search]);

  const fetchSuppliers = (page, searchQuery) => {
    setLoading(true)
    const url = searchQuery
      ? `api/supplier/?page=${page}&size=${rowsPerPage}&supplier_name=${searchQuery}`
      : `api/supplier/?page=${page}&size=${rowsPerPage}`;

    axiosInstance.get(url)
      .then(response => {
        console.log('Fetched data:', response.data);
        setSuppliers(response.data);
        setTotalPages(response.data.totalPages); // Uncomment when totalPages is available
      })
      .catch(error => {
        console.log('Failed to fetch suppliers', error);
      }) .finally(() => { 
        setLoading(false);
      });
  };

  const filteredSuppliers = useMemo(() => {
    if (!suppliers || !Array.isArray(suppliers.data)) {
      console.error('suppliers.data is not an array:', suppliers);
      return [];
    }
    
    return suppliers.data.filter(supplier => {
      console.log('Current supplier_name:', supplier.supplier_name); 
      return supplier.supplier_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  });
  }, [suppliers, search]);

  const handleRowClick = (supplierId) => {
    navigate(`/supplier/details/${supplierId}`);
  };

   // Handle page change
   const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Create pagination items
  const paginationItems = [];
  for (let number = 1; number <= totalPages; number++) {
    if (number === 1 || number === totalPages || (number >= currentPage - 2 && number <= currentPage + 2)) {
      paginationItems.push(
        <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
          {number}
        </Pagination.Item>
      );
    } else if (number === currentPage - 3 || number === currentPage + 3) {
      paginationItems.push(<Pagination.Ellipsis key={`ellipsis-${number}`} />);
    }
  }

  return (
    <div className='maestros-list'>
    <h2>Lista de Proveedores</h2>
    <hr />
    <Form inline style={{ width: '50%' }} onSubmit={(e) => e.preventDefault()}>
        <InputGroup>
          <Form.Control
            type="search"
            placeholder="Buscar por nombre de proveedor"            
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          >
          </Form.Control>
        </InputGroup>
    </Form>

    {loading ? ( 
      <div className='loading-container'>
      <p style={{fontFamily: 'Roboto, sans-serif'}}>Cargando...</p>
      <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem'}} />
      </div>
    ) : (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>ID</th>
          <th>Nombre Proveedor</th>
          <th>Categoría</th>
          <th>País</th>
          <th>Es financiador</th>
          <th>Estado</th>
        </tr>
      </thead>
      <tbody>
        {filteredSuppliers.map(supplier => (
          <tr key={supplier.supplier_id} onClick={() => handleRowClick(supplier.supplier_id)} style={{ cursor: 'pointer' }}>
            <td>{supplier.supplier_id}</td>
            <td>{supplier.supplier_name}</td>
            <td>{supplier.category}</td>
            <td>{supplier.country}</td>
            <td>{supplier.is_financer ? 'Sí' : 'No' }</td>
            <td>{supplier.status}</td>
          </tr>
        ))}
      </tbody>
    </Table>
    )}

    <Row>
      <Col
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ExcelSuppliers/>
      </Col>
      <Col>
      <Pagination>
        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

        {paginationItems}

        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
      </Pagination>
      </Col>
    </Row> 
    </div>
  );
};

export default SuppliersList