import React, {useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import { Spinner, Modal, Button, Row } from 'react-bootstrap';
import '../../appstyles/ClientsCSS/ClientsDetails.css'
import PermissionWrapper from '../../context/PermissionWrapper';

const unitNames = {
    KG: 'Kilogramos',
    G: 'Gramos',
    TON: 'Tonelada',
    TON_S: 'Tonelada corta',
    TON_L: 'Tonelada larga',
    LB: 'Libra',
    OZ: 'Onzas',
    L: 'Litro',
    ML: 'Mililitro',
    GAL_UK: 'Galón UK',
    GAL_US: 'Galón US',
    FCL: 'FCL',
  };

function CostDetails() {
  const axiosInstance = useAxiosPrivate();
  const { costId } = useParams();
  const [cost, setCost] = useState(null);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  useEffect(() => {
    axiosInstance.get(`api/quote-cost/${costId}/`)
      .then(response => {
        setCost(response.data);
      })
      .catch(error => {
        console.error('Failed to fetch cost details', error);
      });
  }, [costId, axiosInstance]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const confirmDelete = () => {
    axiosInstance.delete(`api/quote-cost/${costId}/`)
      .then(() => {
        navigate('/costlist');
      })
      .catch(error => {
        console.error('Failed to delete cost', error);
      });
  };

  const handleEditClick = () => {
    navigate(`/costform/${costId}`);
  };

  if (!cost) {
    return(
    <div className='loading-container'>
      <p style={{fontFamily: 'Roboto, sans-serif'}}>Cargando...</p>
      <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem'}} />
    </div>
    );
  }

  return (
    <div className='maestros-details'>

        <h2>Detalles del Costeo</h2>
        <hr />
        <div className='maestros-details-actions' style={{display: 'flex', flexDirection: 'row'}}>
        <h4>Costeo No. {cost.cost_id}</h4>

        <PermissionWrapper feature="costos" action="update">
            <button 
            className='btn btn-primary' 
            style={{backgroundColor: '#025C5A', borderColor: '#025C5A '}}
            onClick={handleEditClick}
            > 
            Editar
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
            </svg>
            </button>
        </PermissionWrapper>
        
        <PermissionWrapper feature="costos" action="delete">
            <button className='btn btn-danger' onClick={handleShow}>
            Eliminar
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
            </svg>
            </button>
        </PermissionWrapper>

        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
            </svg>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h5>Estas a punto de eliminar el costeo <strong>No. {cost.cost_id}</strong> </h5>
            <p>¿Estás seguro de que lo deseas eliminar? Esta acción no podrá ser revertida</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
            Cerrar
            </Button>
            <Button variant="danger" onClick={confirmDelete}>
            Eliminar
            </Button>
        </Modal.Footer>
        </Modal>
        </div>

        <h4 style={{fontWeight: '700', color: '#07514F'}}>Producto</h4>
        <Row>
        <div className='first-col'>
            <p><strong>Id del producto:</strong> {cost.product_id}</p>
            <p><strong>Nombre del producto:</strong> {cost.product_name} </p>
            <p><strong>Cantidad mínima de orden:</strong> {cost.min_order_quantity}</p>
            <p><strong>Unidad:</strong> {unitNames[cost.min_order_quantity_unit]}</p>
        </div>

        <div className='second-col'>
            <p><strong>Empaque:</strong> {cost.product_detail.packaging_size} {cost.product_detail.unity} en {cost.product_detail.packaging_type} </p>
            <p><strong>Precio por unidad:</strong> {cost.unit_price}</p>
            <p><strong>Moneda:</strong> {cost.currency}</p>
        </div>
        <hr />
        </Row>

        <h4 style={{fontWeight: '700', color: '#07514F'}}>Costeo</h4>
        <div style={{display: 'flex', flexDirection: 'row'}}>
        <div className='first-col'>
            <p><strong>ID del Cliente:</strong> {cost.client_id} </p>
            <p><strong>Nombre del Cliente:</strong> {cost.client_name} </p>
            <p><strong>ID del Proveedor:</strong> {cost.supplier_id} </p>
            <p><strong>Nombre del Proveedor:</strong> {cost.supplier_name} </p>
            <p><strong>Incoterm:</strong> {cost.incoterm} </p>
            <p><strong>Semanas de entrega estimadas:</strong> {cost.estimated_delivery_weeks} </p>
        </div>  

        <div className='second-col'>  
            <p><strong>Días de validez:</strong> {cost.validity} </p>
            <p><strong>Fecha de validez:</strong> {cost.validity_date} </p>
            <p><strong>validez:</strong> {cost.valid ? 'valido' : 'invalido'} </p>
            <p><strong>Fecha de actualización:</strong> {cost.updated_at} </p>
            <p><strong>Observaciones:</strong> {cost.observation} </p>   
        </div>
        </div>

        <Button 
          variant="secondary" 
          style={{marginTop: '20px', width: '20%'}}
          onClick={() => navigate(`/costlist`)}
          >
            Volver
        </Button>

    </div>
  )
}

export default CostDetails